import { FC, useCallback } from 'react';
import { FileUploader as UIFileUploader, IFileUploader, IFileUploaderItem } from '@smart-kasa/ui';

import { getFileUrl } from 'utils/getFileUrl';
import { useLazyGetUploadEndpointQuery } from 'services/api/core/assets/api';
import { useUploadMutation } from 'services/api/aws/api';

export type IFileUploaderProps = Omit<IFileUploader, 'onChange' | 'previewList' | 'onRemove'> & {
  onChange: (files: IFileUploaderItem[]) => void;
  onRemove?: (file: IFileUploaderItem) => void;
  className?: string;
};

export const FileUploader: FC<IFileUploaderProps> = ({
  onChange,
  preview: _preview,
  value,
  onRemove,
  className,
  ...rest
}) => {
  const [getAssetEndpoint] = useLazyGetUploadEndpointQuery();
  const [upload, { isLoading }] = useUploadMutation();

  const load = useCallback(
    async (file: File) => {
      const { data: tempfile } = await getAssetEndpoint({ filename: file.name, type: file.type });

      if (!tempfile) return;

      const { url, fields } = tempfile.data;

      await upload({ url, data: { ...fields, file } });

      const asset = {
        id: fields.key,
        size: file.size,
        filename: file.name,
        mimeType: file.type,
      };

      return asset;
    },
    [getAssetEndpoint, upload]
  );

  const handleRemove = useCallback(
    (index) => {
      const file = Array.isArray(value) ? value.find((_, i) => index === i) : value;

      file && onRemove && onRemove(file);
    },
    [value, onRemove]
  );

  const handleUpload = useCallback(
    async (files?: File[]) => {
      if (!files) return;

      const assets = (await Promise.all(files.map(load))) as IFileUploaderItem[];

      onChange && onChange(assets);
    },
    [load, onChange]
  );

  const previewList = Array.isArray(value) ? value.map((file) => getFileUrl(file)) : [null];
  const preview = value && !Array.isArray(value) && getFileUrl(value);

  return (
    <UIFileUploader
      {...rest}
      className={className}
      value={value}
      isLoading={isLoading}
      onChange={handleUpload}
      previewList={previewList}
      preview={preview}
      onRemove={handleRemove}
    />
  );
};
