import cs from 'classnames';
import { Row, Col } from '@smart-kasa/ui';

import styles from './Report.module.scss';

interface IReportRow {
  title: any;
  value?: any;
  className?: string;
}

export const ReportRow = ({ title, value, className }: IReportRow) => (
  <Row className={cs(styles.row, className)}>
    <Col span={16} className={cs(styles.column, styles.rowTitle)}>
      {title}
    </Col>
    <Col span={8} className={cs(styles.column, styles.rowValue)}>
      {value}
    </Col>
  </Row>
);
