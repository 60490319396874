import { FC, HTMLAttributes, forwardRef } from 'react';
import cs from 'classnames';

import styles from './Report.module.scss';

interface IReportWrapper extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  ref?: any;
}

export const ReportWrapper: FC<IReportWrapper> = forwardRef<HTMLDivElement, IReportWrapper>(
  ({ className, ...rest }, ref) => (
    <div {...rest} ref={ref} className={cs(styles.wrapper, className)} />
  )
);
