import { FC, useState } from 'react';
import { useMatch } from 'react-router-dom';
import { Menu, IMenuItemProps } from '@smart-kasa/ui';

export interface IMenuGroupProps extends IMenuItemProps {
  namespace?: string;
}

export const MenuGroup: FC<IMenuGroupProps> = ({ namespace = '', ...rest }) => {
  const match = useMatch(namespace);
  const [isOpened, setIsOpened] = useState<boolean>(Boolean(match));

  return (
    <Menu.Item
      {...rest}
      active={isOpened}
      componentProps={{ onClick: () => setIsOpened(!isOpened) }}
    />
  );
};
