import cs from 'classnames';
import { Row, Col, Hint } from '@smart-kasa/ui';

import styles from './Report.module.scss';

interface IReportHeaderRow {
  title: any;
  value?: any;
  className?: string;
}

export const ReportHeaderRow = ({ title, value, className }: IReportHeaderRow) => (
  <Row className={cs(styles.headerRow, className)}>
    <Col span={8} className={cs(styles.column, styles.rowTitle)}>
      <Hint margin={0}>{title}</Hint>
    </Col>
    <Col span={16} className={cs(styles.column, styles.rowValue)}>
      <Hint margin={0}>{value}</Hint>
    </Col>
  </Row>
);
